import React from 'react';
import Helmet from 'react-helmet';
import Container from 'reactstrap/lib/Container';
import '../css/Layout.scss';
import { Navbar } from './Navbar';

export interface LayoutProps {
  title: string;
}

export const Layout: React.SFC<LayoutProps> = ({ title, children }) => {
  return (
    <div>
      <Helmet title={title + ' | La Ritournelle'}>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
          integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
          crossOrigin="anonymous"
        />
      </Helmet>
      <Navbar />
      <Container>{children}</Container>
    </div>
  );
};
