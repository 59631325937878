import { Link } from 'gatsby';
import React from 'react';
import Container from 'reactstrap/lib/Container';
import Nav from 'reactstrap/lib/Nav';
import BsNavbar from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavItem from 'reactstrap/lib/NavItem';

export const Navbar: React.SFC<{}> = () => (
  <BsNavbar color="light" light expand="xs">
    <Container>
      <NavbarBrand href="/">La Ritournelle</NavbarBrand>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Link className="nav-link" to="/">
            Home
          </Link>
        </NavItem>
      </Nav>
    </Container>
  </BsNavbar>
);
